import instance from '../instance';
import settings from '../settings';

export default {
  state: {
    reports: null,
    comments: null,
    report: null,
    tracks: [],
    statuses: null,
    page: 1,
    tracksPage: 1,
    filtersForReports: {
      page: 1,
      page_size: 20,
      school_id: null,
      track_id: null,
      course_id: null,
      user_id: null,
      status: null,
      last_comment_date_from: null,
      last_comment_date_to: null,
    },
    tracksReportFilter: {
      page: 1,
      query: '',
      page_size: 10
    },
    tracksReportTotal: 0,
    loading: {
      reports: true,
      comments: true,
      report: true,
      tracks: true,
      statuses: true,
      createComment: false,
    }
  },

  getters: {
    reports: state => state.reports,
    pageReports: state => state.filtersForReports.page,
    loadingReports: state => state.loading,
    comments: state => state.comments,
    report: state => state.report,
    tracks: state => state.tracks,
    statuses: state => state.statuses,
    pageReportsTotal: state => state.page,
    pageTracksAmount: state => state.TracksAmount,
    tracksReportFilter: state => state.tracksReportFilter,
    tracksReportTotal: state => state.tracksReportTotal,
    reportsFiltersApplied: state =>
      Boolean(state.filtersForReports.user_id)
      || Boolean(state.filtersForReports.track_id)
      || Boolean(state.filtersForReports.status)
      || Boolean(state.filtersForReports.last_comment_date_from)
      || Boolean(state.filtersForReports.last_comment_date_to),
  },

  actions: {
    async getReports({ commit, state }, loading = true) {
      if (loading) {
        commit('START_GET_REPORTS_LOADING');
      }

      try {
        const requestId = 'reports';

        const params = Object.fromEntries(Object.entries(state.filtersForReports).filter(([key, value]) => key && value));

        const reports = await instance.get(`${settings.API_URL}/lesson-reports`, { requestId, params });

        commit('SET_REPORTS', reports.data);
      } finally {
        commit('FINISH_GET_REPORTS_LOADING');
      }
    },
    setLoadingReports({ commit }) {
      commit('SET_LOADING_REPORTS')
    },
    async getComments({ commit }, payload) {
      if (payload.loading) {
        commit('START_GET_COMMENTS_LOADING');
      }

      try {
        const comments = await instance.get(`${settings.API_URL}/lesson-reports/${payload.id}/comments`);

        commit('SET_COMMENTS', comments.data);
      } finally {
        commit('FINISH_GET_COMMENTS_LOADING');
      }
    },
    async createReportComment({ commit }, payload) {
      //commit('START_CREATE_COMMENT_LOADING');

      try {
        await instance.post(`${settings.API_URL}/lesson-reports/${payload.id}/comments`, payload.comment);
      } finally {
        commit('FINISH_CREATE_COMMENT_LOADING');
      }
    },
    async getTracks({ commit, state }) {
      const requestId = 'tracks';
      const params = Object.fromEntries(Object.entries(state.tracksReportFilter).filter(([key, value]) => key && value));
      const tracks = await instance.get(`${settings.API_URL}/tracks`, { requestId, params });
      commit('SET_TRACKS', tracks.data);
    },
    async getStatuses({ commit }) {
      const statuses = await instance.get(`${settings.API_URL}/lesson-reports/statuses`);
      commit('SET_STATUSES', statuses.data);
    },
    changeFiltersForReportsClient({commit}, id) {
      commit('CHANGE_FILTERS_FOR_REPORTS_CLIENT', id);
    },
    changeFiltersForReportsTrack({commit}, id) {
      commit('CHANGE_FILTERS_FOR_REPORTS_TRACK', id);
    },
    changeFiltersForReportsStatus({commit}, value) {
      commit('CHANGE_FILTERS_FOR_REPORTS_STATUS', value);
    },
    changeFiltersDateLastCommentForReports({commit}, date) {
      let from = null;
      let to = null;
      if (date && date.from) {
        from = date.from;
      }
      if (date && date.to) {
        to = date.to;
      }
      commit('CHANGE_FILTERS_DATE_LAST_COMMENT_FROM_FOR_REPORTS', from);
      commit('CHANGE_FILTERS_DATE_LAST_COMMENT_TO_FOR_REPORTS', to);
    },
    changePageReports({ commit }, page) {
      commit('CHANGE_PAGE_REPORTS', page);
    },
    resetFiltersReports({ commit }) {
      commit('CHANGE_PAGE_REPORTS', 1);

      commit('CHANGE_FILTERS_FOR_REPORTS_STATUS', null);

      commit('CHANGE_FILTERS_FOR_REPORTS_TRACK', null);

      commit('CHANGE_FILTERS_FOR_REPORTS_CLIENT', null);

      commit('CHANGE_FILTERS_DATE_LAST_COMMENT_FROM_FOR_REPORTS', null);

      commit('CHANGE_FILTERS_DATE_LAST_COMMENT_TO_FOR_REPORTS', null);
    },
    clearReportsComments({ commit }) {
      commit('CLEAR_REPORTS_COMMENTS');
    },
    setFilterQueryReportsTracks({ commit }, query) {
      commit('SET_QUERY_TRACKS', query);
    },
    clearFilterReportsTracks({ commit }) {
      commit('CLEAR_TRACKS_FILTER');
    },
  },

  mutations: {
    CLEAR_REPORTS_COMMENTS(state) {
      state.comments = null;
      state.report = null;
      state.loading = {
        reports: false,
        comments: true,
        report: true,
        tracks: true,
        statuses: true,
        createComment: false,
      };
    },
    SET_QUERY_TRACKS(state, query) {
      state.tracksReportFilter.query = query;
    },
    CLEAR_TRACKS_FILTER(state) {
      state.tracks = [];
      state.tracksReportFilter = {
        page: 1,
        query: '',
        page_size: 10
      },
      state.tracksReportTotal = 0;
    },
    SET_REPORTS(state, reports) {
      state.reports = reports.data;
      state.page = reports.total;
    },
    START_GET_REPORTS_LOADING(state) {
      state.loading.reports = true;
    },
    FINISH_GET_REPORTS_LOADING(state) {
      state.loading.reports = false;
    },
    SET_LOADING_REPORTS(state) {
      state.loading.reports = true;
    },
    SET_COMMENTS(state, comments) {
      state.comments = comments.comments;
      state.report = comments.report;
    },
    START_GET_COMMENTS_LOADING(state) {
      state.loading.comments = true;
      //state.loading.report = true;
    },
    FINISH_GET_COMMENTS_LOADING(state) {
      state.loading.comments = false;
      //state.loading.report = false;
    },
    START_CREATE_COMMENT_LOADING(state) {
      state.loading.createComment = true;
    },
    FINISH_CREATE_COMMENT_LOADING(state) {
      state.loading.createComment = false;
    },
    SET_TRACKS(state, tracks) {
      state.tracksReportFilter.page = state.tracksReportFilter.page+1;
      state.tracks.push(...tracks.data);
      console.log(tracks.total)
      state.tracksReportTotal = tracks.total;
    },
    SET_STATUSES(state, statuses) {
      state.statuses = statuses;
    },
    CHANGE_FILTERS_FOR_REPORTS_CLIENT(state, id) {
      state.filtersForReports.user_id = id;
    },
    CHANGE_FILTERS_FOR_REPORTS_TRACK(state, id) {
      state.filtersForReports.track_id = id;
    },
    CHANGE_FILTERS_FOR_REPORTS_STATUS(state, value) {
      state.filtersForReports.status = value;
    },
    CHANGE_FILTERS_DATE_LAST_COMMENT_FROM_FOR_REPORTS(state, date) {
      state.filtersForReports.last_comment_date_from = date;
    },
    CHANGE_FILTERS_DATE_LAST_COMMENT_TO_FOR_REPORTS(state, date) {
      state.filtersForReports.last_comment_date_to = date;
    },
    CHANGE_PAGE_REPORTS(state, page) {
      state.filtersForReports.page = page;
    },
  },
};