import instance from '../instance';
import settings from '../settings';
import moment from 'moment';

export default {
  state: {
    clients: null,
    clientLessonsGroupedInstructor: null,
    lessonsAllData: null,
    lessons: [],
    lessonsPackagesAllData: null,
    lessonsPackages: [],
    client: null,
    clientDocuments: null,
    notesAllData: null,
    notes: [],
    galleryList: [],
    galleryMeta: null,
    pageNotes: 1,
    filtersForClients: {
      page: 1,
      page_size: 20,
      assigned: '',
      reminder: '',
      instructor_id: '',
      query: ''
    },
    filtersForGallery: {
      page: 1,
    },
    filtersForClientLessons: {
      page: 1,
      page_size: 20,
      status: null,
      date_from: null,
      date_to: null
    },
    filtersClientLessonsPackages: {
      page: 1,
      page_size: 10,
    },
    loading: {
      clients: true,
      clientLessonsGroupedInstructor: true,
      lessons: true,
      lessonsPackages: true,
      client: true,
      editClient: false,
      deleteClient: false,
      changePassword: false,
      newClient: false,
      notes: true,
      createNote: false
    },
    loadingGallery: {
      gallery: true,
    }
  },

  getters: {
    clients: state => state.clients,
    pageClients: state => state.filtersForClients.page,
    filtersForClients: state => state.filtersForClients,
    filterForClientsAssigned: state => state.filtersForClients.assigned,
    filterForClientsNotifications: state => state.filtersForClients.reminder,
    clientsFiltersApplied: state =>
      Boolean(state.filtersForClients.instructor_id)
      || Boolean(state.filtersForClients.assigned)
      || Boolean(state.filtersForClients.reminder)
      || Boolean(state.filtersForClients.query),
    clientLessonsGroupedInstructor: state => state.clientLessonsGroupedInstructor,
    clientLessonAllData: state => state.lessonsAllData,
    clientLessons: state => state.lessons,
    clientLessonsPackagesAllData: state => state.lessonsPackagesAllData,
    clientLessonsPackages: state => state.lessonsPackages,
    clientLessonsFiltersStatus: state => state.filtersForClientLessons.status,
    clientLessonsFiltersDate: state => Boolean(state.filtersForClientLessons.date_from) && Boolean(state.filtersForClientLessons.date_to),
    clientLessonsFiltersApplied: state =>
      Boolean(state.filtersForClientLessons.status)
      || Boolean(state.filtersForClientLessons.date_from) && Boolean(state.filtersForClientLessons.date_to),
    client: state => state.client,
    clientDocuments: state => state.clientDocuments,
    clientNotesAllData: state => state.notesAllData,
    clientNotes: state => state.notes,
    loadingClients: state => state.loading,
    galleryList: state => state.galleryList,
    galleryListByDate: (state) => {
      return state.galleryList.reduce((acc, item) => {
        if (!acc[item.date]) {
          acc[item.date] = [];
        }
        acc[item.date].push(item);
        return acc;
      }, {});
    },
    loadingGallery: state => state.loadingGallery,
    galleryMeta: state => state.galleryMeta,
  },

  actions: {
    async getClients({ commit, state }, loading = true) {
      if (loading) {
        commit('START_GET_CLIENTS_LOADING');
      }

      try {
        const requestId = 'clients';

        const params = Object.fromEntries(Object.entries(state.filtersForClients).filter(([key, value]) => key && value));

        const clients = await instance.get(`${settings.API_URL}/lessons/clients`, { requestId, params } );

        commit('SET_CLIENTS', clients.data);
      } finally {
        commit('FINISH_GET_CLIENTS_LOADING');
      }
    },
    clearClients({ commit }) {
      commit('CLEAR_CLIENTS');
    },
    async getGallery({ commit, state }, payload) {
      if (payload.loading) {
        commit('START_GET_GALLERY_LOADING');
      }

      try {
        const requestId = 'gallery';

        const params = Object.fromEntries(Object.entries(state.filtersForGallery).filter(([key, value]) => key && value));
        const gallery = await instance.get(`${settings.API_URL}/clients/${payload.id}/telegram-bot-gallery`, { requestId, params });

        commit('SET_GALLERY', {
          photos: gallery.data.data,
          meta: gallery.data.meta,
        });
      } finally {
        commit('FINISH_GET_GALLERY_LOADING');
      }
    },
    clearGallery({ commit }) {
      commit('CLEAR_GALLERY');
    },
    changePageClients({ commit }, page) {
      commit('CHANGE_PAGE_CLIENTS', page);
    },
    changeFiltersClientsInstructorId({ commit }, id) {
      commit('CHANGE_FILTERS_CLIENTS_INSTRUCTORID_CLIENTS', id);
    },
    changeFiltersClientsSearch({ commit }, search) {
      commit('CHANGE_FILTERS_CLIENTS_SEARCH', search);
    },
    changeFiltersClientsAssigned({ commit }, value) {
      commit('CHANGE_FILTERS_CLIENTS_ASSIGNED', value);
    },
    changeFiltersClientsNotifications({ commit }, value) {
      commit('CHANGE_FILTERS_CLIENTS_REMINDER', value);
    },
    resetFiltersClients({ commit }) {
      commit('CHANGE_PAGE_CLIENTS', 1);

      commit('CHANGE_FILTERS_CLIENTS_INSTRUCTORID_CLIENTS', null);

      commit('CHANGE_FILTERS_CLIENTS_SEARCH', null);

      commit('CHANGE_FILTERS_CLIENTS_REMINDER', null);

      commit('CHANGE_FILTERS_CLIENTS_ASSIGNED', null);
    },
    setLoadingClients({ commit }) {
      commit('SET_LOADING_CLIENTS')
    },
    async getClientLessons({ commit, state }, payload) {
      if (payload.loading) {
        commit('START_GET_CLIENT_LESSONS_LOADING');
      }

      try {
        const params = Object.fromEntries(Object.entries(state.filtersForClientLessons).filter(([key, value]) => key && value));

        const lessonsAllData = await instance.get(`${settings.API_URL}/clients/${payload.idClient}/lessons`, { params });

        if (payload.filters) {
          commit('CLEAR_CLIENT_LESSONS');
        }

        commit('SET_CLIENT_LESSONS', lessonsAllData.data);
      } finally {
        commit('FINISH_GET_CLIENT_LESSONS_LOADING');
      }
    },
    clearClientLessons({ commit }) {
      commit('CLEAR_CLIENT_LESSONS');
    },
    changePageClientLessons({ commit }, page) {
      commit('CHANGE_PAGE_CLIENT_LESSONS', page);
    },
    changeFiltersClientLessonsStatus({ commit }, status) {
      commit('CHANGE_FILTERS_CLIENT_LESSONS_STATUS', status);
    },
    changeFiltersClientLessonsDate({ commit }, date) {
      commit('CHANGE_FILTERS_CLIENT_LESSONS_DATE', date);
    },
    resetFiltersClientLessons({ commit }) {
      commit('CHANGE_PAGE_CLIENT_LESSONS', 1);

      commit('CHANGE_FILTERS_CLIENT_LESSONS_STATUS', null);

      commit('CHANGE_FILTERS_CLIENT_LESSONS_DATE', { from: null, to: null });
    },
    async getClientLessonsGroupedInstructor({ commit }, payload) {
      if (payload.loading) {
        commit('START_GET_CLIENT_LESSONS_GROUPED_INSTRUCTOR_LOADING');
      }

      try {
        const lessons = await instance.get(`${settings.API_URL}/lessons/clients/${payload.idClient}`);

        commit('SET_CLIENT_LESSONS_GROUPED_INSTRUCTOR', lessons.data);
      } finally {
        commit('FINISH_GET_CLIENT_LESSONS_GROUPED_INSTRUCTOR_LOADING');
      }
    },
    async getClientLessonsPackages({ commit, state }, payload) {
      if (payload.loading) {
        commit('START_GET_CLIENT_LESSONS_PACKAGES_LOADING');
      }

      try {
        const packages = await instance.get(`${settings.API_URL}/lessons/clients/${payload.idClient}/packages`, { params: state.filtersClientLessonsPackages});

        commit('SET_CLIENT_LESSONS_PACKAGES', packages.data);
      } finally {
        commit('FINISH_GET_CLIENT_LESSONS_PACKAGES_LOADING');
      }
    },
    clearClientLessonsPackages({ commit }) {
      commit('CLEAR_CLIENT_LESSONS_PACKAGES');
    },
    changePageClientLessonsPackages({ commit }, page) {
      commit('CHANGE_PAGE_CLIENT_LESSONS_PACKAGES', page);
    },
    async returnToAvailable({ commit }, payload) {
      const lessonAbailable = await instance.post(`${settings.API_URL}/clients/${payload.idClient}/lessons/${payload.idLesson}/cancel`);

      const lesson = {
        id: payload.idLesson,
        lesson: lessonAbailable.data.lesson
      }

      commit('RETURN_TO_AVAILABLE', lesson);
    },
    async getClient({ commit }, idClient) {
      commit('START_GET_CLIENT_LOADING');

      try {
        const client = await instance.get(`${settings.API_URL}/clients/${idClient}`);

        commit('SET_CLIENT', client.data.client);
        commit('SET_CLIENT_DOCUMENTS', client.data.documents);
      } finally {
        commit('FINISH_GET_CLIENT_LOADING');
      }
    },
    clearClient({ commit }) {
      commit('CLEAR_CLIENT');
    },
    addClientInListClients({ commit }, client) {
      commit('ADD_CLIENT_IN_LIST_CLIENTS', client);
    },
    async editClient({ commit }, payload) {
      commit('START_EDIT_CLIENT_LOADING');
      payload.client.birthdate = moment(payload.client.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD');

      try {
        await instance.patch(`${settings.API_URL}/clients/${payload.idClient}`, payload.client);
      } finally {
        commit('FINISH_EDIT_CLIENT_LOADING');
        payload.client.birthdate = moment(payload.client.birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY');
      }
    },
    async changePasswordClient({ commit }, payload) {
      commit('START_CHANGE_PASSWORD_CLIENT_LOADING');

      try {
        await instance.post(`${settings.API_URL}/clients/${payload.idClient}/password`, payload.password);
      } finally {
        commit('FINISH_CHANGE_PASSWORD_CLIENT_LOADING');
      }
    },
    async createClient({ commit }, data) {
      commit('START_CREATE_CLIENT_LOADING');

      try {
        await instance.post(`${settings.API_URL}/clients`, data);
      } finally {
        commit('FINISH_CREATE_CLIENT_LOADING');
      }
    },
    async deleteClient({ commit }, idClient) {
      commit('START_DELETE_CLIENT_LOADING');

      try {
        await instance.delete(`${settings.API_URL}/clients/${idClient}`);
      } finally {
        commit('FINISH_DELETE_CLIENT_LOADING');
      }

    },
    async getClientNotes({ commit, state }, payload) {
      if (payload.loading) {
        commit('START_GET_CLIENT_NOTES_LOADING');
      }

      if (payload.setNotesAfterCreate) {
        commit('RESET_PAGE_CLIENT_NOTES');
      }

      try {
        const notesAllData = await instance.get(`${settings.API_URL}/clients/${payload.idClient}/notes?page=${state.pageNotes}`);

        if (payload.setNotesAfterCreate) {
          commit('CLEAR_CLIENT_NOTES');

          commit('SET_CLIENT_NOTES', notesAllData.data);
        } else {
          commit('SET_CLIENT_NOTES', notesAllData.data);
        }
      } finally {
        commit('FINISH_GET_CLIENT_NOTES_LOADING');
      }
    },
    clearClientNotes({ commit }) {
      commit('CLEAR_CLIENT_NOTES');
    },
    resetPageClientNotes({ commit }) {
      commit('RESET_PAGE_CLIENT_NOTES');
    },
    async createClientNote({ commit }, payload) {
      commit('START_CREATE_CLIENT_NOTE_LOADING');

      try {
        await instance.post(`${settings.API_URL}/clients/${payload.idClient}/notes`, payload.note);
      } finally {
        commit('FINISH_CREATE_CLIENT_NOTE_LOADING');
      }
    },
    async deleteClientNote({ commit }, payload) {
      await instance.delete(`${settings.API_URL}/clients/${payload.idClient}/notes/${payload.idNote}`);

      commit('DELETE_CLIENT_NOTE', payload.idNote);
    },
    
    // eslint-disable-next-line no-empty-pattern
    async downloadDocuments({ }, id){
      let documents = await instance.get(`${settings.API_URL}/clients/${id}/documents/download`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([documents.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Документы пользователя ID ${id}.zip`);
      document.body.appendChild(link);
      link.click();
    },
    // eslint-disable-next-line no-empty-pattern
    async setGalleryViewed({ }, id) {
      await instance.post(`${settings.API_URL}/clients/${id}/has-not-new-gallery-item`);
    },
    
  },

  mutations: {
    SET_CLIENTS(state, clients) {
      state.clients = clients;
    },
    CLEAR_CLIENTS(state) {
      state.clients = null;
    },
    SET_GALLERY(state, { photos, meta }) {
      state.galleryList.push(...photos)
      state.filtersForGallery.page++;
      state.galleryMeta = meta;
    },
    CLEAR_GALLERY(state) {
      state.galleryList = [];
      state.filtersForGallery.page = 1;
      state.loadingGallery.gallery = true;
    },
    START_GET_GALLERY_LOADING(state) {
      state.loadingGallery.gallery = true;
    },
    FINISH_GET_GALLERY_LOADING(state) {
      state.loadingGallery.gallery = false;
    },
    CHANGE_PAGE_CLIENTS(state, page) {
      state.filtersForClients.page = page;
    },
    CHANGE_FILTERS_CLIENTS_INSTRUCTORID_CLIENTS(state, id) {
      state.filtersForClients.instructor_id = id;
    },
    CHANGE_FILTERS_CLIENTS_SEARCH(state, search) {
      state.filtersForClients.query = search;
    },
    CHANGE_FILTERS_CLIENTS_ASSIGNED(state, value) {
      state.filtersForClients.assigned = value;
    },
    CHANGE_FILTERS_CLIENTS_REMINDER(state, value) {
      state.filtersForClients.reminder = value;
    },
    START_GET_CLIENTS_LOADING(state) {
      state.loading.clients = true;
    },
    FINISH_GET_CLIENTS_LOADING(state) {
      state.loading.clients = false;
    },
    SET_LOADING_CLIENTS(state) {
      state.loading.clients = true;
    },

    SET_CLIENT_LESSONS(state, lessonsAllData) {
      state.lessonsAllData = lessonsAllData;

      if (lessonsAllData.lessons.length) {
        state.lessons.push(...lessonsAllData.lessons);

        state.filtersForClientLessons.page++;
      }
    },
    CLEAR_CLIENT_LESSONS(state) {
      state.lessonsAllData = null;
      state.lessons = [];
    },
    CHANGE_PAGE_CLIENT_LESSONS(state, page) {
      state.filtersForClientLessons.page = page;
    },
    CHANGE_FILTERS_CLIENT_LESSONS_STATUS(state, status) {
      state.filtersForClientLessons.status = status;
    },
    CHANGE_FILTERS_CLIENT_LESSONS_DATE(state, date) {
      state.filtersForClientLessons.date_from = date.from;

      state.filtersForClientLessons.date_to = date.to;
    },
    START_GET_CLIENT_LESSONS_LOADING(state) {
      state.loading.lessons = true;
    },
    FINISH_GET_CLIENT_LESSONS_LOADING(state) {
      state.loading.lessons = false;
    },
    RETURN_TO_AVAILABLE(state, lesson) {
      const indexLesson = state.lessons.findIndex(el => el.lesson_id === lesson.id);

      state.lessons.splice(indexLesson, 1, lesson.lesson);
    },

    SET_CLIENT_LESSONS_GROUPED_INSTRUCTOR(state, lessons) {
      state.clientLessonsGroupedInstructor = lessons;
    },
    START_GET_CLIENT_LESSONS_GROUPED_INSTRUCTOR_LOADING(state) {
      state.loading.clientLessonsGroupedInstructor = true;
    },
    FINISH_GET_CLIENT_LESSONS_GROUPED_INSTRUCTOR_LOADING(state) {
      state.loading.clientLessonsGroupedInstructor = false;
    },

    SET_CLIENT_LESSONS_PACKAGES(state, lessonsAllData) {
      state.lessonsPackagesAllData = lessonsAllData;

      if (lessonsAllData.packages.length) {
        state.lessonsPackages.push(...lessonsAllData.packages);

        state.filtersClientLessonsPackages.page++;
      }
    },
    CLEAR_CLIENT_LESSONS_PACKAGES(state) {
      state.lessonsPackagesAllData = null;
      state.lessonsPackages = [];
    },
    CHANGE_PAGE_CLIENT_LESSONS_PACKAGES(state, page) {
      state.filtersClientLessonsPackages.page = page;
    },
    START_GET_CLIENT_LESSONS_PACKAGES_LOADING(state) {
      state.loading.lessonsPackages = true;
    },
    FINISH_GET_CLIENT_LESSONS_PACKAGES_LOADING(state) {
      state.loading.lessonsPackages = false;
    },

    SET_CLIENT(state, client) {
      state.client = client;
    },
    SET_CLIENT_DOCUMENTS(state, documents) {
      state.clientDocuments = documents;
    },
    START_GET_CLIENT_LOADING(state) {
      state.loading.client = true;
    },
    FINISH_GET_CLIENT_LOADING(state) {
      state.loading.client = false;
    },
    CLEAR_CLIENT(state) {
      state.client = null;
    },
    ADD_CLIENT_IN_LIST_CLIENTS(state, client) {
      if (!state.clients.clients.find(el => el.client_id === client.client_id)) {
        state.clients.clients.unshift(client);
      }
    },

    START_EDIT_CLIENT_LOADING(state) {
      state.loading.editClient = true;
    },
    FINISH_EDIT_CLIENT_LOADING(state) {
      state.loading.editClient = false;
    },

    START_CHANGE_PASSWORD_CLIENT_LOADING(state) {
      state.loading.changePassword = true;
    },
    FINISH_CHANGE_PASSWORD_CLIENT_LOADING(state) {
      state.loading.changePassword = false;
    },

    START_CREATE_CLIENT_LOADING(state) {
      state.loading.newClient = true;
    },
    FINISH_CREATE_CLIENT_LOADING(state) {
      state.loading.newClient = false;
    },

    START_DELETE_CLIENT_LOADING(state) {
      state.loading.deleteClient = true;
    },
    FINISH_DELETE_CLIENT_LOADING(state) {
      state.loading.deleteClient = false;
    },

    SET_CLIENT_NOTES(state, notesAllData) {
      state.notesAllData = notesAllData;

      if (notesAllData.notes.length) {
        state.notes.push(...notesAllData.notes);

        state.pageNotes++;
      }
    },
    CLEAR_CLIENT_NOTES(state) {
      state.notesAllData = null;
      state.notes = [];
    },
    RESET_PAGE_CLIENT_NOTES(state) {
      state.pageNotes = 1;
    },
    START_GET_CLIENT_NOTES_LOADING(state) {
      state.loading.notes = true;
    },
    FINISH_GET_CLIENT_NOTES_LOADING(state) {
      state.loading.notes = false;
    },

    START_CREATE_CLIENT_NOTE_LOADING(state) {
      state.loading.createNote = true;
    },
    FINISH_CREATE_CLIENT_NOTE_LOADING(state) {
      state.loading.createNote = false;
    },

    DELETE_CLIENT_NOTE(state, idNote) {
      const indexNote = state.notes.findIndex(el => el.note_id == idNote);

      state.notes.splice(indexNote, 1);
    }
  },
};