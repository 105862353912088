<template>
  <div class="account-content">
    <GoBack />
    <div class="account-subtitle pt-0 mb-3">Медиа клиента {{client?.name?.full}}</div>
    <AppLoader v-if="loadingGallery.gallery" />
    <div v-else class="photos">
      <div v-for="(photos, date) in galleryListByDate" :key="date" class="photos__block">
        <p class="photos__date">{{ moment(date).format('DD.MM.YYYY') }}</p>
        <div class="photos__list">
          <a 
            data-fancybox="gallery"
            v-for="(photo, index) in photos"
            :key="`${photo.media_item_id}_${index}`"
            :href="photo?.url" 
            class="photos__list-item"
          >
            <img 
              v-if="photo?.type.includes('image')"
              :src="photo?.url"
              alt="" 
              class="photos__list-img"
            >
            <div v-if="photo?.type.includes('video')" class="photos__list-video">
              <video>
                <source :src="photo?.url" type="video/mp4" />
              </video>
            </div>
          </a>
        </div>
      </div>
      <infinite-loading
        @infinite="infiniteHandler"
        class="mt-4"
      >
        <div slot="spinner">
          <AppLoader2 :mini="true" />
        </div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import GoBack from '@/components/GoBack.vue';
import AppLoader from '@/components/AppLoader/1.vue';
import AppLoader2 from '@/components/AppLoader/2.vue';
import InfiniteLoading from 'vue-infinite-loading';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
  name: 'ClientGallery',

  components: {
    GoBack,
    AppLoader,
    AppLoader2,
    InfiniteLoading,
  },

  computed: {
    ...mapGetters({
      gallery: 'gallery',
      galleryList: 'galleryList',
      galleryListByDate: 'galleryListByDate',
      client: 'client',
      loadingGallery: 'loadingGallery',
      meta: 'galleryMeta',
    }),

    totalPhotos() {
      return this.galleryList.length;
    },
  },

  async created() {
    let payload_gallery = {
      id: this.$route.params.id,
      laoading: true,
    };

    await this.getGallery(payload_gallery);

    await this.setGalleryViewed(payload_gallery.id);

    await this.getClient(payload_gallery.id);
  },

  destroyed() {
    this.clearGallery();
    this.clearClient();
  },

  methods: {
    ...mapActions([
      'getGallery',
      'getClient',
      'clearGallery',
      'clearClient',
      'setGalleryViewed',
    ]),
    infiniteHandler($state) {
      const totalPhotos = this.totalPhotos;

      if (totalPhotos < this.meta.total) {
        const payload_gallery = {
          id: this.$route.params.id,
          laoading: true,
        };

        this.getGallery(payload_gallery)
          .then(() => {
            $state.loaded();
          });
      } else {
        $state.complete();
      }
    },
    moment,
  },

  mounted() {
    Fancybox.bind('[data-fancybox="gallery"]');
  },
}
</script>

<style>
.photos__list-video {
  width: 100%;
  height: 100%;
  position: relative;
}
.photos__list-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
}
.photos__list-video:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  position: absolute;
}
.photos__list-video:after {
  content: '';
  width: 29px;
  height: 29px;
  background-image: url(~@/assets/img/play.svg);
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>